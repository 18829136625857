<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <!-- Name Building -->
        <b-col cols="12">
          <b-form-group label-for="name-class">
            <template v-slot:label>
              Tên lớp <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên lớp"
              rules="required"
            >
              <b-form-input
                id="name-class"
                v-model="targetData.className"
                name="nameClass"
                placeholder="Tên lớp"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Code -->
        <b-col cols="5">
          <b-form-group label-for="code-class">
            <template v-slot:label>
              Mã lớp <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Mã lớp"
              rules="required"
            >
              <b-form-input
                id="code-class"
                v-model="targetData.code"
                type="text"
                placeholder="Mã lớp"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Số thự tự"
            label-for="orderNo"
          >
            <b-form-input
              :disabled="!targetData.orderNo"
              id="orderNo"
              v-model="targetData.orderNo"
              placeholder="Sinh tự động"
              type="number"
            />
          </b-form-group>
        </b-col>
        <!-- trạng thái -->
        <b-col cols="4">
          <b-form-group
            label="Trạng thái"
            label-for="status"
          >
            <b-form-select
              id="status"
              v-model="targetData.status"
              :options="optionsStatus"
            />
          </b-form-group>
        </b-col>
        <!-- Hệ đào tạo -->
        <b-col cols="6">
          <b-form-group label-for="trainingSystemId">
            <template v-slot:label>
              Hệ đào tạo <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Hệ đào tạo"
              rules="required"
            >
              <v-select
                id="trainingSystemId"
                :disabled="!isCreated"
                v-model="targetData.trainingSystemId"
                :options="trainingSystems"
                :reduce="option => option.value"
                :clearable="false"
                @input="changeTrainingSystem"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- Khóa học -->
        <b-col cols="6">
          <b-form-group label-for="courseId">
            <template v-slot:label>
              Khóa học <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Khóa học"
              rules="required"
            >
              <v-select
                id="courseId"
                :disabled="!isCreated"
                v-model="targetData.courseId"
                :options="courses"
                :reduce="option => option.value"
                :clearable="false"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!-- Khoa/bộ môn -->
        <b-col cols="12">
          <b-form-group label="Khoa/bộ môn" label-for="departmentId">
            <v-select
              id="departmentId"
              :disabled="!isCreated"
              v-model="targetData.departmentId"
              :options="departments"
              :reduce="option => option.value"
              :clearable="false"
              @input="changeDepartment"
            />
          </b-form-group>
        </b-col>
        <!-- Chuyên ngành -->
        <b-col cols="12">
          <b-form-group label-for="majorId">
            <template v-slot:label>
              Chuyên ngành <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Chuyên ngành"
              rules="required"
            >
              <v-select
                id="majorId"
                :disabled="!isCreated"
                v-model="targetData.majorId"
                :options="majors"
                :reduce="option => option.value"
                :clearable="false"
                @input="changeMajor"
              />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--  Giáo viên chủ nhiệm-->
        <b-col cols="6">
          <b-form-group
            label="Giáo viên chủ nhiệm"
            label-for="teacher"
          >
            <b-form-select
              id="teacher"
              v-model="targetData.teacherId"
              :options="optTeachers"
            />
          </b-form-group>
        </b-col>
        <!--  Cố vấn học tập-->
        <b-col cols="6">
          <b-form-group
            label="Cố vấn học tập"
            label-for="consultant"
          >
            <b-form-select
              id="status"
              v-model="targetData.consultantId"
              :options="optTeachers"
            />
          </b-form-group>
        </b-col>
        <!--  Ngày bắt đầu-->
        <b-col cols="6">
          <b-form-group
            label="Ngày bắt đầu"
            label-for="start_date"
          >
            <b-form-datepicker
              id="datepicker-dateformat1"
              v-model="targetData.startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="vi"
              format="dd/mm/yyyy"
            />
          </b-form-group>
        </b-col>
        <!-- Ngày kết thúc -->
        <b-col cols="6">
          <b-form-group
            label="Ngày kết thúc"
            label-for="end_date"
          >
            <b-form-datepicker
              id="datepicker-dateformat2"
              v-model="targetData.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="vi"
              format="dd/mm/yyyy"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <div class="float-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BCol, BRow, BForm, BFormGroup, BFormInput, BFormSelect, BFormDatepicker, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { STATUSES } from '@/const/status'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { getUser } from '@/auth/utils'

export default {
  name: 'FormClass',
  directives: {
    Ripple,
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormDatepicker,
    BFormInvalidFeedback,
    vSelect,
  },
  props: {
    dataSendClass: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      targetData: {
        id: null,
        code: null,
        name: null,
        classId: null,
        majorId: null,
        capacity: null,
        courseId: null,
        startDate: null,
        endDate: null,
        teacherId: null,
        consultantId: null,
        trainingSystemId: null,
        status: null,
        orderNo: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      courses: 'dropdown/courses',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      teachers: 'teacher/teachers',
      trainingSystems: 'dropdown/trainingSystems',
      programmes: 'dropdown/programmes',
    }),
    optionsStatus() {
      return STATUSES.map(item => ({ value: item.value, text: item.label }))
    },
    optTeachers() {
      return this.teachers.map(item => ({ value: item.id, text: item.name }))
    },
    isCreated() {
      return !this.dataSendClass.id
    },
  },
  watch: {
    // listening change data init
    // 'targetData.trainingSystemId': function (newVal) {
    //   this.changeTrainingSystem(newVal)
    // },
    // 'targetData.departmentId': function (newVal) {
    //   this.changeDepartment(newVal)
    // },
    // 'targetData.majorId': function (newVal) {
    //   this.changeMajor(newVal)
    // },
  },
  async created() {
    const dataObj = {
      currentPage: 1,
      itemsPerPage: 1000,
      organizationId: getUser().orgId,
    }
    // init data FROM API
    if (!this.isCreated) {
      this.targetData = { ...this.dataSendClass }
      dataObj.trainingSystemId = this.targetData.trainingSystemId
      dataObj.courseId = this.targetData.courseId
      dataObj.departmentid = this.targetData.departmentId
      dataObj.majorId = this.targetData.majorId
      await Promise.all([
        this.getCourses(dataObj),
        this.getMajors(dataObj),
        this.getTeachers(dataObj),
      ])
    } else {
      await Promise.all([
        this.getTrainingSystems(dataObj),
        this.getDepartments(dataObj),
      ])
    }

    if (!this.dataSendClass.status) {
      this.targetData.status = this.optionsStatus[0].value
    }
  },
  methods: {
    ...mapActions({
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getTeachers: 'teacher/readTeachers',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getProgrammes: 'dropdown/getProgrammes',
    }),
    // handle even
    handleSaveData(e) {
      this.$emit('saveDataClass', this.targetData)
      e.preventDefault()
    },

    async changeTrainingSystem(id) {
      const param = {
        organizationId: getUser().orgId,
        trainingSystemId: id,
      }
      await this.getCourses(param)
      if (id) {
        this.targetData.courseId = this.courses[0].value
      } else {
        this.targetData.courseId = null
      }
    },

    async changeDepartment(id) {
      const param = {
        organizationId: getUser().orgId,
        trainingSystemId: this.targetData.trainingSystemId,
        departmentId: id,
      }
      this.targetData.majorId = null
      this.targetData.teacherId = null

      await this.getMajors(param)
    },
    async changeMajor(id) {
      this.targetData.teacherId = null
      const param = {
        organizationId: getUser().orgId,
        courseId: this.targetData.courseId,
        trainingSystemId: this.targetData.trainingSystemId,
        departmentId: this.targetData.departmentId,
        majorId: id,
      }
      await this.getProgrammes(param)
      if (this.programmes.length === 0) {
        alert('Chuyên ngành này chưa có chương trình đào tạo.\nVui lòng chọn chuyên ngành khác!')
        this.targetData.majorId = null
      } else {
        await this.getTeachers({
          ...param,
          currentPage: 1,
          itemsPerPage: 200,
        })
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

</style>
